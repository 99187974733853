import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const GamingRoomDesign = ({ data }) => {
  return (
    <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-brand-blue max-w-7xl">
      <div className="flex items-center justify-center w-full md:w-1/2">
        <div className="w-full px-4">
          <h3 className="mb-4 text-3xl font-bold leading-none uppercase text-brand-blue">
            Gaming Room Design
          </h3>
          <p className="mb-4">
            MCG is on-point when it comes to the latest styling trends, room
            layouts, furniture, gaming systems and technologies. The team
            regularly travels to the world’s most successful venues to bring a
            touch of Las Vegas or Macau to the NSW Mid North Coast. James,
            Dennis and the MCG team will work with you to understand your venue,
            customers and staff. They will give independent advice on how to
            renovate your gaming room, expand for user amenity, accessibility
            and comfort for customers and staff. Your decision to engage the
            independent expertise of Mid Coast Gaming for your venue upgrade
            will ensure the right products and systems are in place to maximise
            return on investment.
          </p>
          <p className="my-8 md:mb-0">
            <Link
              className="inline-block px-4 py-3 text-sm font-bold leading-none tracking-wide uppercase border-2 rounded hover:bg-brand-blue border-brand-blue hover:text-white"
              to="/game-room-design"
            >
              Find out more
            </Link>
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 md:pl-8">
        <div className="relative aspect-w-16 aspect-h-9">
          <div className="absolute inset-0 flex">
            <Image
              className="flex-1"
              fluid={data.GamingRoomDesign.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

GamingRoomDesign.propTypes = {
  data: PropTypes.object,
};

export default GamingRoomDesign;
