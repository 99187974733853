import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import ElectronicGamingMachines from './ElectronicGamingMachines';
import GamingRoomDesign from './GamingRoomDesign';
import PlayerLoyaltySystems from './PlayerLoyaltySystems';
import ProjectManagement from './ProjectManagement';
import CoffeeEquipment from './CoffeeEquipment';
import CashHandlingEquipment from './CashHandlingEquipment';
import GamingMachineBases from './GamingMachineBases';

const Services = () => {
  const data = useStaticQuery(graphql`
    fragment ServicesImage on File {
      childImageSharp {
        fluid(maxWidth: 888, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    query galleryQuery {
      ElectronicGamingMachines: file(
        relativePath: { eq: "electronic-gaming-machines.jpg" }
      ) {
        ...ServicesImage
      }
      GamingRoomDesign: file(relativePath: { eq: "gaming-room-design.jpg" }) {
        ...ServicesImage
      }
      GamingMachineBases: file(relativePath: { eq: "gmba-1.jpg" }) {
        ...ServicesImage
      }
      PlayerLoyaltySystems: file(
        relativePath: { eq: "player-loyalty-systems.jpg" }
      ) {
        ...ServicesImage
      }
      ProjectManagement: file(relativePath: { eq: "project-management.jpg" }) {
        ...ServicesImage
      }
      CoffeeEquipment: file(relativePath: { eq: "coffee-equipment.jpg" }) {
        ...ServicesImage
      }
      EffortlessCRM: file(relativePath: { eq: "effortless-crm.jpg" }) {
        ...ServicesImage
      }
      CashHandlingEquipment: file(
        relativePath: { eq: "cash-handling-equipment.png" }
      ) {
        ...ServicesImage
      }
    }
  `);
  return (
    <div id="services">
      <h2 className="mt-16 mb-12 text-3xl font-bold text-center uppercase text-brand-blue">
        Our Services
      </h2>
      <ElectronicGamingMachines data={data} />
      <CashHandlingEquipment data={data} />
      <GamingMachineBases data={data} />
      <GamingRoomDesign data={data} />
      <PlayerLoyaltySystems data={data} />
      <ProjectManagement data={data} />
      <CoffeeEquipment data={data} />
    </div>
  );
};

export default Services;
