import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const PlayerLoyaltySystems = ({ data }) => {
  return (
    <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-brand-blue max-w-7xl">
      <div className="flex items-center justify-center w-full md:w-1/2">
        <div className="w-full px-4">
          <h3 className="mb-4 text-3xl font-bold leading-none uppercase text-brand-blue">
            Player Loyalty Systems
          </h3>
          <p className="mb-4 md:mb-20">
            Systems make life easier for you, your staff and customers – if
            tailored for your circumstances. Mid Coast Gaming will help you make
            all the right moves, integrating gaming machines, links, loyalty
            systems and vending machines. Mid Coast Gaming will also take care
            of the installation and ongoing technical support to keep things
            running smoothly
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 md:pl-8">
        <div className="relative aspect-w-16 aspect-h-9">
          <div className="absolute inset-0 flex">
            <Image
              className="flex-1"
              fluid={data.PlayerLoyaltySystems.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

PlayerLoyaltySystems.propTypes = {
  data: PropTypes.object,
};

export default PlayerLoyaltySystems;
