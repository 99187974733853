import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const GamingMachineBases = ({ data }) => {
  return (
    <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-brand-blue max-w-7xl">
      <div className="flex items-center justify-center w-full md:w-1/2">
        <div className="w-full px-4">
          <h3 className="mb-4 text-3xl font-bold leading-none uppercase text-brand-blue">
            GAMING MACHINE BASES
          </h3>
          <p className="mb-4">
            We have teamed up with GMB Australia for the very best gaming
            machine bases. Gaming Machine Bases Australia design and manufacture
            premium electronic gaming machine bases for gaming venues around
            Australia. Select from bases, screens, accessories and design your
            own gaming room to fit your specifications.
          </p>
          <p className="my-8 md:mb-0">
            <Link
              className="inline-block px-4 py-3 text-sm font-bold leading-none tracking-wide uppercase border-2 rounded hover:bg-brand-blue border-brand-blue hover:text-white"
              to="/gmba"
            >
              Find out more
            </Link>
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 md:pl-8">
        <div className="relative aspect-w-16 aspect-h-9">
          <div className="absolute inset-0 flex">
            <Image
              className="flex-1"
              fluid={data.GamingMachineBases.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

GamingMachineBases.propTypes = {
  data: PropTypes.object,
};

export default GamingMachineBases;
