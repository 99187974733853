import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const ElectronicGamingMachines = ({ data }) => {
  return (
    <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-brand-blue max-w-7xl">
      <div className="flex items-center justify-center w-full md:w-1/2">
        <div className="w-full px-4">
          <h3 className="mb-4 text-3xl font-bold leading-none uppercase text-brand-blue">
            Electronic Gaming Machines
          </h3>
          <p className="mb-4 md:mb-8">
            Technical know-how and service is our strength. Mid Coast Gaming has
            a breadth of experience installing, maintaining, repairing and
            providing technical support for all brands of gaming machines,
            loyalty systems and links. More than fixing problems as they arise,
            Mid Coast Gaming analyses cash-flow, usage and room layout to build
            the ultimate atmosphere and experience for your patrons. Proudly
            independent, Mid Coast Gaming will provide unbiased product advice
            to give your customers the entertainment they love.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 md:pl-8">
        <div className="relative aspect-w-16 aspect-h-9">
          <div className="absolute inset-0 flex">
            <Image
              className="flex-1"
              fluid={data.ElectronicGamingMachines.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ElectronicGamingMachines.propTypes = {
  data: PropTypes.object,
};

export default ElectronicGamingMachines;
