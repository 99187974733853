import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const CoffeeEquipment = ({ data }) => {
  return (
    <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 max-w-7xl">
      <div className="flex items-center justify-center w-full md:w-1/2">
        <div className="w-full px-4">
          <h3 className="mb-4 text-3xl font-bold leading-none uppercase text-brand-blue">
            Coffee Equipment
          </h3>
          <p className="mb-4 md:mb-40">
            There’s no such thing as the simple cup of coffee anymore. Mid Coast
            Gaming will ensure your venue serves customers the very ‘crema’ of
            the crop with Hennessy Coffee. From the beans to the machine, MCG
            will take care of everything. Premium espresso machines, fully
            automatic machines and all associated equipment will be provided,
            installed, maintained and integrated with customer loyalty systems,
            swipe-cards, and gaming player points-for-product redemption.
            Comprehensive barista training for your staff is also part of the
            Hennessy Coffee experience.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 md:pl-8">
        <div className="relative aspect-w-16 aspect-h-9">
          <div className="absolute inset-0 flex">
            <Image
              className="flex-1"
              fluid={data.CoffeeEquipment.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CoffeeEquipment.propTypes = {
  data: PropTypes.object,
};

export default CoffeeEquipment;
