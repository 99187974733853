import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const Banner = ({ data }) => (
  <div className="relative mt-16 overflow-hidden aspect-w-16 aspect-h-7 md:aspect-h-4">
    <div className="absolute inset-x-0 top-0 flex">
      <Image
        className="self-start w-full"
        fluid={data.Banner.childImageSharp.fluid}
      />
    </div>
  </div>
);

Banner.propTypes = {
  data: PropTypes.object,
};

export default Banner;
