import React from 'react';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const ProjectManagement = ({ data }) => {
  return (
    <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-brand-blue max-w-7xl">
      <div className="flex items-center justify-center w-full md:w-1/2">
        <div className="w-full px-4">
          <h3 className="mb-4 text-3xl font-bold leading-none uppercase text-brand-blue">
            Project Management
          </h3>
          <p className="mb-4 md:mb-48">
            Take your next project up, up and away with the professional
            experience of Mid Coast Gaming. The team will manage the renovation
            and construction of your gaming room from concept design to complete
            and functioning state-of-the-art space. MCG artfully manages
            technology roll-outs or can retrofit software systems and hardware
            to your room.
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 md:pl-8">
        <div className="relative aspect-w-16 aspect-h-9">
          <div className="absolute inset-0 flex">
            <Image
              className="flex-1"
              fluid={data.ProjectManagement.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ProjectManagement.propTypes = {
  data: PropTypes.object,
};

export default ProjectManagement;
