import 'keen-slider/keen-slider.min.css';

import { useKeenSlider } from 'keen-slider/react';
import * as React from 'react';
import PropTypes from 'prop-types';

import Logo from '../images/logo-monochrome.png';

function Carousel({ children }) {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [isMounted, setIsMounted] = React.useState(false);
  const sliderContainerRef = React.useRef(null);

  const [pause, setPause] = React.useState(false);
  const timer = React.useRef();

  const [ref, slider] = useKeenSlider({
    loop: true,
    duration: 2000,
    slidesPerView: 1,
    mounted: () => setIsMounted(true),
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    dragStart: () => {
      setPause(true);
    },
    dragEnd: () => {
      setPause(false);
    },
  });

  React.useEffect(() => {
    const preventNavigation = (event) => {
      // Center point of the touch area
      const touchXPosition = event.touches[0].pageX;
      // Size of the touch area
      const touchXRadius = event.touches[0].radiusX || 0;

      if (
        touchXPosition - touchXRadius < 10 ||
        touchXPosition + touchXRadius > window.innerWidth - 10
      ) {
        event.preventDefault();
      }
    };

    const { current } = sliderContainerRef;

    current?.addEventListener('touchstart', preventNavigation);

    return () => {
      current?.removeEventListener('touchstart', preventNavigation);
    };
  }, []);

  function handleKeyDown(e) {
    if (e.key === 'ArrowLeft') slider.prev();
    if (e.key === 'ArrowRight') slider.next();
  }

  React.useEffect(() => {
    ref.current.addEventListener('mouseover', () => {
      setPause(true);
    });
    ref.current.addEventListener('mouseout', () => {
      setPause(false);
    });
  }, [ref]);

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next();
      }
    }, 3000);
    return () => {
      clearInterval(timer.current);
    };
  }, [pause, slider]);

  return (
    <article className="relative flex items-center justify-center">
      <div
        ref={sliderContainerRef}
        onKeyDown={(e) => handleKeyDown(e)}
        tabIndex={0}
        role="region"
        className="relative flex-1 pt-16"
      >
        <ul
          ref={ref}
          className="h-full transition-opacity duration-150 keen-slider focus:z-10 carousel"
          style={{
            opacity: isMounted ? 1 : 0,
            maxHeight: '40rem',
            minHeight: '25rem',
          }}
        >
          {React.Children.map(children, (child) => {
            // Add the keen-slider__slide className to children
            if (React.isValidElement(child)) {
              return {
                ...child,
                props: {
                  ...child.props,
                  className: `${
                    child.props.className
                      ? `${String(child.props.className)} `
                      : ''
                  }keen-slider__slide ${isMounted ? 'block' : 'hidden'}`,
                },
              };
            }
            return child;
          })}
        </ul>
      </div>
      <div className="absolute flex flex-col items-center justify-center w-full h-full">
        <img
          className="h-40 mx-auto md:h-64"
          src={Logo}
          alt="MID COAST GAMING"
        />
        <h1 className="text-3xl leading-tight tracking-wide text-center text-white uppercase md:text-5xl ont-bold">
          Welcome to
          <br />
          MID COAST GAMING
        </h1>
      </div>
    </article>
  );
}

Carousel.propTypes = {
  children: PropTypes.node,
};

export default Carousel;
