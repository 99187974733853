import React from 'react';
import { graphql } from 'gatsby';
import GatsbyImage from 'gatsby-image';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import OurStory from '../components/OurStory';
import Banner from '../components/Banner';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import Employment from '../components/Employment';
import ContactUs from '../components/ContactUs';
import Carousel from '../components/Carousel';
import MapBanner from '../components/MapBanner';

const IndexPage = ({ data }) => (
  <Layout homePage>
    <SEO title="Home" />
    <HeroCarousel slides={[data.slide1, data.slide2, data.slide3]} />
    <OurStory data={data} />
    <Banner data={data} />
    <Services />
    <Testimonials />
    <Employment data={data} />
    <MapBanner data={data} />
    <ContactUs data={data} />
  </Layout>
);
IndexPage.propTypes = {
  data: PropTypes.object,
};

const HeroCarousel = ({ slides }) => {
  return (
    <Carousel>
      {slides.map(({ childImageSharp }) => (
        <li key={nanoid()} className="flex">
          <GatsbyImage
            fluid={childImageSharp.fluid}
            className="flex-1"
            alt=""
          />
        </li>
      ))}
    </Carousel>
  );
};

HeroCarousel.propTypes = {
  slides: PropTypes.array,
};

export default IndexPage;

export const query = graphql`
  query HomePageQuery {
    Hero: file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    OurStory: file(relativePath: { eq: "our-story-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Banner: file(relativePath: { eq: "banner-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Employment: file(relativePath: { eq: "employment.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1063) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    MapBanner: file(relativePath: { eq: "map.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 818) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mcgLogo: file(relativePath: { eq: "mcg-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 173, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide1: file(relativePath: { eq: "slider/1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide2: file(relativePath: { eq: "slider/2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slide3: file(relativePath: { eq: "slider/3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        address
        facebook
        googleMaps
        phone
        phoneFormatted
        title
      }
    }
  }
`;
