import React from 'react';
import Carousel from 'nuka-carousel';
import { FaChevronCircleLeft, FaChevronCircleRight } from 'react-icons/fa';

import Stars from '../images/5-stars.png';

const Testimonials = () => (
  <div
    id="testimonials"
    className="flex items-center justify-center px-4 py-12 text-white bg-brand-blue"
  >
    <Carousel
      autoplay
      autoplayInterval={5000}
      cellAlign="center"
      dragging
      easing="easeCubicInOut"
      enableKeyboardControls
      swiping
      wrapAround
      renderCenterLeftControls={({ previousSlide }) => (
        <button
          aria-label="Previous slide"
          className="p-4"
          onClick={previousSlide}
          type="button"
        >
          <FaChevronCircleLeft className="hidden text-3xl text-white sm:block" />
        </button>
      )}
      renderCenterRightControls={({ nextSlide }) => (
        <button
          aria-label="Next slide"
          className="p-4"
          onClick={nextSlide}
          type="button"
        >
          <FaChevronCircleRight className="hidden text-3xl text-white sm:block" />
        </button>
      )}
      renderBottomCenterControls={null}
    >
      <blockquote className="max-w-lg mx-auto font-bold text-center">
        <img className="h-8 mx-auto mb-4" src={Stars} alt="" />
        "Mid Coast Gaming has kept the engine room of the Laurieton United
        Services Club running for 25 years. Aaron and the team exceed our
        expectations every time they work with us. Mid Coast Gaming is more than
        a service company. The team has the wisdom of long-term industry
        experience on their side, bringing insights on the latest trends and
        technologies to our venue. The Mid Coast Gaming team work around the
        clock to ensure minimum disruption to our customers and staff. Their
        service is seamless which is essential for any hospitality-focused
        business."
        <footer className="mt-4 font-normal">
          <cite>
            Robert Dwyer, Secretary Manager Laurieton United Services Club
          </cite>
        </footer>
      </blockquote>

      <blockquote className="max-w-lg mx-auto font-bold text-center">
        <img className="h-8 mx-auto mb-4" src={Stars} alt="" />
        "We have been associated with Mid Coast Gaming for over 20 years. Why?
        The team is prompt and effective. The service provided is pre-emptive
        not just reactive. They are a trusted provider of service to our gaming
        machines and ancillary equipment. Above what you might get elsewhere
        they provide a broad knowledge of advice on gaming operations including
        layout, base design, and industry trends. In addition, MCG is an active
        supporter of golf events in out annual calendar."
        <footer className="mt-4 font-normal">
          <cite>Daniel Constable, CEO Port Macquarie Golf Club</cite>
        </footer>
      </blockquote>
      <blockquote className="max-w-lg mx-auto font-bold text-center">
        <img className="h-8 mx-auto mb-4" src={Stars} alt="" />
        "We are proud to have had a partnership of more than 20 years with the
        Team from Mid Coast Gaming. In our industry it is always refreshing to
        see a contractor who is as committed to the success of our venue as much
        as we are. Aaron and his team are committed to both quality and service
        and have a genuine interest in the continued success of our business.
        Their industry insight, professionalism, ‘can do’ attitude and
        incredible sense of community continue to be appreciated and admired by
        our Team. Whether it be as a sound board for ideas and concepts or as a
        team of tradespeople who maintain our floor, Mid Coast Gaming never fail
        to impress. We genuinely appreciate their commitment to the continued
        operation and growth of our business"
        <footer className="mt-4 font-normal">
          <cite>Paul Allan, CEO of Club Taree</cite>
        </footer>
      </blockquote>
    </Carousel>
  </div>
);

export default Testimonials;
