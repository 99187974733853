import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const Employment = ({ data }) => {
  return (
    <div
      id="employment"
      className="w-full px-4 pt-12 pb-0 mx-auto text-center md:pt-16 md:pb-8 max-w-7xl"
    >
      <h2 className="mb-8 text-3xl font-bold leading-none uppercase text-brand-blue">
        Work With Us
      </h2>
      <p className="mb-4">
        Mid Coast Gaming is an award-winning business based in Port Macquarie,
        with a subsidiary office in Tighes Hill, Newcastle. We are renowned for
        the value we bring to our customers through industry experience,
        knowledge, independent advice, and our ability to handle complex
        projects over a diverse customer base – from small family-owned
        businesses to large, multinational companies.
      </p>
      <p className="mb-4">
        We value people who are great communicators, understand the value in
        building genuine relationships with clients, are interested in the
        ever-changing world of technology, who are dynamic thinkers, who love
        finding innovative solutions and are ready to dive into the fast-paced
        world of hospitality servicing.
      </p>
      <p className="mb-4">Send your CV to the contact below.</p>

      <div className="flex justify-center">
        <Link
          to="/contact-us"
          className="inline-block px-4 py-3 mx-auto my-8 text-sm font-bold leading-none tracking-wide text-center uppercase border-2 rounded hover:bg-brand-blue border-brand-blue hover:text-white md:mb-0"
        >
          Contact us today
        </Link>
      </div>
    </div>
  );
};

Employment.propTypes = {
  data: PropTypes.object,
};

export default Employment;
