import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const Services = ({ data }) => (
  <div id="our-story">
    <h2 className="px-4 mt-12 mb-8 text-3xl font-bold uppercase md:text-center md:px-0 text-brand-blue">
      We keep you ahead of the game - Our Story
    </h2>
    <div className="flex flex-wrap w-full mx-auto max-w-7xl">
      <div className="flex items-center justify-center w-full md:w-1/2">
        <div className="w-full px-4">
          <p className="mb-4">
            In hospitality, the aim above all is to provide service and care to
            patrons and customers.
          </p>
          <p className="mb-4">
            When the hospitality industry needs service, Mid Coast Gaming has
            been there since 1985. We’ve been at service to all types of venues
            from Newcastle all the way to the Queensland border.
          </p>
          <p className="mb-4">
            Innovation and room design analysis is at the core of what we do at
            Mid Coast Gaming. Long time technicians James Templeton and Dennis
            Warren purchased the company, seizing an opportunity to deliver
            outstanding service, renovation review, gaming room performance, and
            create the ideal space in your hospitality venue through our Gaming
            Machine Bases Australia arm – the same level of service and quality
            our clients have come to expect for over thirty years.
          </p>
          <p className="mb-4">
            Mid Coast Gaming is founded on building the business through solid
            partnerships from within and without. James, Dennis and Business
            Development Manager Neale Johnson work together to service from the
            lower Central Coast all the way up the Mid North Coast towards
            Queensland.
          </p>
          <p className="mb-4">
            In addition to servicing gaming venues, we can support all player
            reward solutions. We have also forged partnerships with leading
            hardware support companies, as well as being the main distributor
            for Hennessy Coffee from the Hunter to the North Coast of NSW. We
            also specialise in multiple cash management systems. We service and
            operate with over 300 clients, tailoring solutions to their needs.
          </p>
          <p className="mb-4">
            We’re all about creating a real community to help local business
            thrive in an ever-changing marketplace.
          </p>
          <p className="mb-4">
            Just like Aaron was at service to the Mid North Coast and Queensland
            hospitality, we’re continuing his proud mission of providing quality
            services and products, propelling you to be the best of the best in
            your customers’ hearts and minds.
          </p>
          <p className="my-8 md:mb-0">
            <Link
              className="inline-block px-4 py-3 text-sm font-bold leading-none tracking-wide uppercase border-2 rounded hover:bg-brand-blue border-brand-blue hover:text-white"
              to="/contact-us"
            >
              Contact us today
            </Link>
          </p>
        </div>
      </div>
      <div className="relative w-full md:w-1/2">
        <div className="px-4 md:absolute md:inset-0 md:overflow-hidden">
          <Image className="" fluid={data.OurStory.childImageSharp.fluid} />
        </div>
      </div>
    </div>
  </div>
);

Services.propTypes = {
  data: PropTypes.object,
};

export default Services;
