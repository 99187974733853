import React from 'react';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

const CashHandlingEquipment = ({ data }) => {
  return (
    <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-brand-blue max-w-7xl">
      <div className="flex items-center justify-center w-full md:w-1/2">
        <div className="w-full px-4">
          <h3 className="mb-4 text-3xl font-bold leading-none uppercase text-brand-blue">
            Cash Handling Equipment
          </h3>
          <p className="mb-4 md:mb-24">
            Dealing with cash correctly and securely is vital for the safety and
            convenience of customers and staff. MCG can advise you on the right
            equipment then install, service and maintain automatic teller
            machines, cash handling and cash recycling equipment. Along with
            this technology comes training from the MCG team for your staff to
            be supported in this important task.
          </p>
          <p className="my-8 md:mb-0">
            <Link
              className="inline-block px-4 py-3 text-sm font-bold leading-none tracking-wide uppercase border-2 rounded hover:bg-brand-blue border-brand-blue hover:text-white"
              to="/cash-handling-equipment"
            >
              Find out more
            </Link>
          </p>
        </div>
      </div>
      <div className="w-full px-4 md:w-1/2 md:pl-8">
        <div className="relative aspect-w-16 aspect-h-9">
          <div className="absolute inset-0 flex">
            <Image
              className="self-start w-full"
              fluid={data.CashHandlingEquipment.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

CashHandlingEquipment.propTypes = {
  data: PropTypes.object,
};

export default CashHandlingEquipment;
